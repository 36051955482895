<template>
  <div class="page-layout details" v-if="pageData && pageData.attributes">
    
    <HeaderIntroImage underlined central :headerMetadata="pageData"></HeaderIntroImage>
    
    <div class="container" v-if="this.loading">
      <br>
      <h3>Loading data ...</h3>
      <br>
    </div>

    <div class="container" v-if="pageData.attributes.field_page_main_content && !this.loading">

      <div class="page-body blue-title" v-html="this.pageData.attributes.field_page_main_content.value"></div>

      <div class="page-body image-container">
        <img src="../assets/CFQT_2_5_Overview.jpg" alt="CFQT_2_5_Overview">
        <br><br><br>
      </div>

      <div class="competence-list">
        <div v-for="(item, index) of menuItems" :key="'comp'+index" :class="'comp-'+(index+1)">
          
          <div :class="['title-container', 'color-'+(index+1)]">
            <h3 :class="['title', 'color-'+(index+1)]">{{ item.label }}</h3> 
          </div>
          <div :class="['competence-cell', 'cell-'+(index+1)+'-'+(ind+1)]" v-for="(c, ind) in item.children" :key="'el'+ind">

            <CompetenceLink class="gray-bg voices" :point="calcpoint(index, ind)" :item="c" :level="1"/>
            <!-- <div class="gray-bg prerequisites">
              Prerequisites for 
              <br>
              <div class="bold">{{ c.label }}</div>
              <br>

              <div v-if="calcpoint(index, ind)=='3'" class="prereq">
                <div class="ref-1"><span class="point">1</span>Concenpts of Quantum Physics</div>
                <div class="ref-2"><span class="point">2</span>Physical Foundation of Quantum Technologies</div>
                <div class="ref-8"><span class="point">8</span>Practical and Soft Skills</div>
              </div>
              <div v-if="calcpoint(index, ind)=='4'" class="prereq">
                <div class="ref-3"><span class="point">3</span>Enabling Technologies</div>
                <div class="ref-8"><span class="point">8</span>Practical and Soft Skills</div>
              </div>
              <div v-if="calcpoint(index, ind)=='5'" class="prereq">
                <div class="ref-4"><span class="point">4</span>Hardware for Quantum Computers and Sensors</div>
                <div class="ref-8"><span class="point">8</span>Practical and Soft Skills</div>
              </div>
              <div v-if="calcpoint(index, ind)=='6'" class="prereq">
                <div class="ref-4"><span class="point">4</span>Hardware for Quantum Computers and Sensors</div>
                <div class="ref-8"><span class="point">8</span>Practical and Soft Skills</div>
              </div>
              <div v-if="calcpoint(index, ind)=='7'" class="prereq">
                <div class="ref-3"><span class="point">3</span>Enabling Technologies</div>
                <div class="ref-8"><span class="point">8</span>Practical and Soft Skills</div>
              </div>

            </div> -->
          </div>

        </div>
      </div>
<!-- 
      <br>
      <br> -->

      <div class="infos">
        <h4 class="blue" v-html="this.pageData.field_ref_page_content[0].attributes.field_titolo.value"></h4>
        <div class="info-grid">
          <div class="big-cell" v-html="this.pageData.field_ref_page_content[0].attributes.field_text_with_summary.value"></div>
          <div class="svg-cell">
            <img src="../assets/CFQT_v2_5_Triangle_Keyword.svg" alt="CFQT">
          </div>

          <!--<div class="small-cell legend-grid">
             <div class="legend">
              <span class="a-num">A1</span>Awareness <p></p>
            </div>
            <div class="legend"><span class="a-num">A2</span>Exploration</div>
            <div class="legend"><span class="b-num">B1</span>Adaption</div>
            <div class="legend"><span class="b-num">B2</span>Expertise</div>
            <div class="legend"><span class="c-num">C1</span>Specialisation</div>
            <div class="legend"><span class="c-num">C2</span>Innovation</div>
          </div> -->
        </div>
<!-- 
        <br>
        <br> -->

        <h4 class="blue">Resources to download</h4>

        <a v-for="(att, i) in pageData.field_attachments" :key="'att'+i"
          class="btn download-link slim bold plain" :href="endpoint + att.attributes.uri.url" download
          target="_blank">
            <span class="download icon-right" v-if="pageData.relationships.field_attachments.data[i].meta.description != '' ">
              {{pageData.relationships.field_attachments.data[i].meta.description}}
            </span>

            <span v-else class="download icon-right">{{ att.attributes.filename }}</span>
        </a>

        <br>
        <br>

        <h4 class="blue">External Links</h4>
        <a v-for="(link, i) in pageData.attributes.field_external_link" :key="'link'+i"
          class="btn border slim plain" :href="link.uri" target="_blank">
            <span class="external icon-right" v-if="link.title != '' ">
              {{ link.title }}
            </span>

            <span v-else class="external icon-right" style="word-break: break-all;">{{ link.uri }}</span>
        </a>


      </div>
    </div>
  </div>
</template>

<script>
import CompetenceLink from '../components/atomic-elements/CompetenceLink.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
//import MemberCard from '../components/layout-blocks/MemberCard.vue'
import { fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'competence-framework',
  components: {
    HeaderIntroImage,
    CompetenceLink
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "Competence Framework",
          tag: "",
          subtitle: ""
        }
      },
      menuItems: null,
      currentPageID: "acd21e29-a4ed-4ac3-8218-6f5a6d037caa",
      countcell: 0,
      loading: true
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    endpoint(){
      return process.env.VUE_APP_REDIRECT
    }
  },
  methods: {
    calcpoint(index ,ind) {
      var n = 0

      if(index>0) {
        this.menuItems.forEach((m, i) => {
          if(i<index)
            n += m.children.length
        })
        n += (ind+1)
      } else {
        n = ind + 1
      }

      return n.toString()
    },  
    goto() {
      this.$router.push('/get-involved')
    },
    buildTree(items) {
      //console.log(items)
      var ch = [] 
      
      items.forEach(item => {
        //console.log(item)
        if(item.has_children)
          ch.push({
            label: item.title,
            url: "",
            children: this.buildTree(item.subtree)
          })
        else 
          ch.push({
            label: item.title,
            url: item.url,
            children: []
          })
      })

      return ch
    },
    async fetchCompetenceFramework() {
      this.loading = true
      var items = []

      var l = this.lang!=this.defaultLang ? ('/' + this.lang) : ''

      await fetch(`${process.env.VUE_APP_ENDPOINT}${l}jconfig/menu/competence-framework`)
        .then(res => res.json())
        .then(json => items = json.menu )

      this.menuItems = this.buildTree(items)
      //console.log(this.menuItems)

      this.loading = false
    }
  },
  async mounted() {
    await fetchSinglePage(this.currentPageID, {
      include: ['field_ref_page_content', 'field_ref_image', 'field_attachments']
    })
    .then(res => {
      this.pageData = res
      //console.log(this.pageData)
    })

    await this.fetchCompetenceFramework()
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';


.image-container {
  position: relative;
  img {
    width: 100%;
  }
}

.title-container {
  width: 100%;
  border-bottom: 2px solid #000;
}

.color-1 { color: $color1; border-color: $color1; }
.color-2 { color: $color3; border-color: $color3; }
.color-3 { color: $color8; border-color: $color8; }

.title {
  margin: .5em auto .25em auto;
  text-align: center;
}

.prerequisites {
  color: #707070;
  .bold {
    text-transform: uppercase;
    color: #707070;
  }
}

.ref-1, .ref-2,
.ref-3, .ref-4, 
.ref-5, .ref-6,
.ref-7, .ref-8 {
  position: relative;
  margin: 0 0 .5em 3em;
  min-height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:before {
    content: '';
    display: block;
    width: 1.5em;
    height: 1.5em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateZ(45deg);
    left: -2.5em;
    z-index: 1;
    
  }
  .point {
    display: block;
    width: 1.5em;
    height: 1.5em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -2.5em;
    z-index: 2;

    text-align: center;
    color: #fff;
    font-family: $primaryFont;
  }
}

.ref-1 {
  &:before {  background-color: $color1; }
  color: $color1;
}
.ref-2 {
  &:before {  background-color: $color2; }
  color: $color2;
}
.ref-3 {
  &:before {  background-color: $color3; }
  color: $color3;
}
.ref-4 {
  &:before {  background-color: $color4; }
  color: $color4;
}
.ref-8 {
  &:before {  background-color: $color8; }
  color: $color8;
}

.competence-list {
  display: flex;
  flex-direction: column;
}

.competence-cell {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  margin: 1.5em 0;
  > div { 
    padding: 1em 2em;
    grid-column: span 1;
  }
}

.voices {
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  .big-cell { grid-column: span 1; }
  .small-cell { grid-column: span 1; }
}

.legend-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: yellowgreen;
  font-weight: 700;

  .legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    margin: .5em 0;
  }

  span { 
    color: $primaryColor; 
    margin-right: .5em;
  }

  span.a-num {
    display: block;
    background-color: yellowgreen;
    padding: 5px;
    border-radius: 5px;
    width: fit-content;
    margin-left: 6px;
  }

  span.b-num {
    display: block;
    background-color: yellowgreen;
    padding: 8px;
    border-radius: 5px;
    margin-left: 3px;
    width: fit-content;
  }

  span.c-num {
    display: block;
    background-color: yellowgreen;
    padding: 11px;
    border-radius: 5px;
    width: fit-content;
  }
}

.svg-cell {
  margin: 4rem 0rem;
}

@media (min-width: 576px) {
  .info-grid .legend-grid .legend {
    margin: .5em auto .5em 3em;
  }
}

@media (min-width: 1024px) {
  .competence-cell {
    grid-template-columns: repeat(2, 1fr);
    div:first-child { grid-column: span 2; }
    div:nth-child(2) { grid-column: span 1; }
  }
  .voices {
    grid-template-columns: repeat(3, 1fr);
    ::v-deep > .menu-item { grid-column: span 1; }
    ::v-deep > .submenu { grid-column: span 2; }
  }

  .info-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
    .big-cell { grid-column: span 2; }
    .small-cell { grid-column: span 1; }

    .legend-grid .legend { margin: 1em 0 1em 1em;}
  }

  .svg-cell {
    margin: 0rem 0rem;
  }

}

@media (min-width: 1500px) {
  .voices {
    grid-template-columns: repeat(5, 1fr);
    ::v-deep > .menu-item { grid-column: span 2; }
    ::v-deep > .submenu { grid-column: span 3;}
  }
}


</style>