<template>
    <keep-alive>
        <div v-if="item.children.length == 0" class="menu-item no-child">
            <span class="point">{{ this.point }}</span>
            {{ item.label }}
        </div>

        <div v-else>
            <div :class="['comp-level-'+level, 'menu-item', 'child']" @click="opensubmenu()" ref="menu-item">
                <span class="point">{{ this.point }}</span>
                {{ item.label }}
            </div>

            <div :class="['submenu', 'sub-level-'+level]" ref="menu-submenu">
                <competence-link v-for="(child, index) of item.children" :key="'submenu'+index" :item="child"
                    :level="level+1" :point="point + '.' + (index+1)"></competence-link>
            </div>
        </div>

    </keep-alive>
</template>

<script>
    export default {
        name: 'competence-link',
        props: {
            item: Object,
            level: Number,
            point: String
        },
        methods: {
            opensubmenu() {
                var event = event || window.event;
                event.target.classList.toggle('expanded')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/variables.scss";

    .menu-item {
        padding: .5em 2em .5em 0;
        position: relative;

        +.submenu {
            display: none;
        }

        .point {
            display: none;
        }

        //first submenu always visible
        &.comp-level-1 {
            position: relative;



            +.submenu {
                display: block;
            }

            &:before {
                content: '';
                display: block;
                width: 1.5em;
                height: 1.5em;
                position: absolute;
                top: .75em;
                right: -.25em;
                z-index: 1;
                transform: rotateZ(45deg);
            }

            .point {
                display: block;
                width: 1.5em;
                height: 1.5em;
                position: absolute;
                top: .35em;
                right: -.35em;
                z-index: 2;
                text-align: center;
                color: #fff;
                font-family: $primaryFont;
            }
        }


        //vertical line for menu hierarchy
        &:not(.comp-level-1, .comp-level-2, .submenu.sub-level-1 > .no-child):before {
            content: '';
            position: absolute;
            left: -1.5em;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 1em;
            height: 1px;
        }

        //expansion icon and label style
        &.child:not(.comp-level-1) {
            cursor: pointer;

            &:after {
                content: '+';
                color: darken($hoverGray, 30%);
                font-size: 1.2em;
                display: block;
                position: absolute;
                right: .5em;
                top: 50%;
                transform: translateY(-50%);
            }

            &:hover {
                font-weight: 700;
            }
        }

        //primary voice style
        &.comp-level-1 {
            pointer-events: none;
            text-transform: uppercase;
            font-family: $primaryFont;
            font-size: 1.2rem;

            span {
                font-size: 1.5rem;
            }
        }

        //secondary voice style
        &.comp-level-2 {
            padding: 1em 3em 1em 0;
            display: flex;
            align-items: center;
            height: 5em;
            font-size: .9rem;
            //text-transform: uppercase; 
            //width: 90%;
        }

        //style for voices with no childs, or final leaves of tree
        &.no-child {
            cursor: default;
            pointer-events: none;
        }

        //voices style when expanded
        &.expanded:not(.comp-level-1) {
            +.submenu {
                display: block;
            }

            &:after {
                content: '-';
                right: .7em;
            }

            &.comp-level-2 {
                font-weight: 700;
                text-transform: uppercase;
            }

            &.comp-level-3 {
                padding: .5em 1em .5em .25em;
                background: darken($hoverGray, 5%);
            }
        }
    }

    .submenu {
        //padding: 1.5em 0 1.5em 2em;
        padding-left: 2em;
        position: relative;

        &.sub-level-1 {
            padding-left: 0;
            margin-top: 1em;
        }

        &:before {
            content: '';
            display: block;
            height: calc(100% - 1em);
            width: 1px;
            position: absolute;
            left: .5em;
            top: 0;
        }
    }

    .cell-1-1 {

        .comp-level-1,
        .menu-item.expanded,
        .menu-item.child:hover,
        .submenu.sub-level-1>.no-child,
        .menu-item.expanded+.submenu>.menu-item {
            color: $color1;
        }

        .comp-level-1 .point:before,
        .menu-item:before,
        .menu-item.expanded+.submenu:before {
            background: $color1;
        }
    }

    .cell-1-2 {

        .comp-level-1,
        .menu-item.expanded,
        .menu-item.child:hover,
        .submenu.sub-level-1>.no-child,
        .menu-item.expanded+.submenu>.menu-item {
            color: $color2;
        }

        .comp-level-1 .point:before,
        .menu-item:before,
        .menu-item.expanded+.submenu:before {
            background: $color2;
        }
    }

    .cell-2-1 {

        .comp-level-1,
        .menu-item.expanded,
        .menu-item.child:hover,
        .submenu.sub-level-1>.no-child,
        .menu-item.expanded+.submenu>.menu-item {
            color: $color3;
        }

        .comp-level-1 .point:before,
        .menu-item:before,
        .menu-item.expanded+.submenu:before {
            background: $color3;
        }
    }

    .cell-2-2 {

        .comp-level-1,
        .menu-item.expanded,
        .menu-item.child:hover,
        .submenu.sub-level-1>.no-child,
        .menu-item.expanded+.submenu>.menu-item {
            color: $color4;
        }

        .menu-item:before,
        .menu-item.expanded+.submenu:before {
            background: $color4;
        }
    }


    .cell-3-1 {

        .comp-level-1,
        .menu-item.expanded,
        .menu-item.child:hover,
        .submenu.sub-level-1>.no-child,
        .menu-item.expanded+.submenu>.menu-item {
            color: $color5;
        }

        .menu-item:before,
        .menu-item.expanded+.submenu:before {
            background: $color5;
        }
    }

    .cell-3-2 {

        .comp-level-1,
        .menu-item.expanded,
        .menu-item.child:hover,
        .submenu.sub-level-1>.no-child,
        .menu-item.expanded+.submenu>.menu-item {
            color: $color6;
        }

        .menu-item:before,
        .menu-item.expanded+.submenu:before {
            background: $color6;
        }
    }



    .cell-3-3 {
        .comp-level-1,
        .menu-item.expanded,
        .menu-item.child:hover,
        .submenu.sub-level-1>.no-child,
        .menu-item.expanded+.submenu>.menu-item {
            color: $color7;
        }
        .menu-item:before,
        .menu-item.expanded+.submenu:before {
            background: $color7;
        }
    }

    .cell-3-4 {
        .comp-level-1,
        .menu-item.expanded,
        .menu-item.child:hover,
        .submenu.sub-level-1>.no-child,
        .menu-item.expanded+.submenu>.menu-item {
            color: $color8;
        }
        .menu-item:before,
        .menu-item.expanded+.submenu:before {
            background: $color8;
        }
    }



    @media (min-width: 576px) {
        .menu-item {
            &.comp-level-1 {
                padding-left: .5em;

                &:before {
                    content: '';
                    display: block;
                    width: 1.8em;
                    height: 1.8em;
                    position: absolute;
                    top: 22px;
                    left: -2.25em;
                    z-index: 1;
                    transform: rotateZ(45deg);
                }

                .point {
                    display: block;
                    width: 1.8em;
                    height: 1.8em;
                    position: absolute;
                    top: .75em;
                    left: -2em;
                    z-index: 2;
                    text-align: center;
                    color: #fff;
                    font-family: $primaryFont;
                }
            }

            &.comp-level-2 {
                padding: 0 3em 0 0;
                height: 3em;
                font-size: 1rem;
            }

            &.comp-level-2,
            +.submenu.sub-level-1>.no-child {

                &:before {
                    content: '';
                    display: block;
                    width: 1.8em;
                    height: 1.8em;
                    position: absolute;
                    top: 50%;
                    left: -3em;
                    z-index: 1;
                    transform: translateY(-50%) rotateZ(45deg);
                }

                .point {
                    display: block;
                    width: 1.8em;
                    height: 1.8em;
                    position: absolute;
                    top: 50%;
                    left: -46px;
                    z-index: 2;
                    transform: translateY(-50%) translateY(.25em);
                    text-align: center;
                    font-size: 14px;
                    color: #fff;
                    font-family: $secondaryFont;
                }
            }
        }
    }

    @media (min-width: 1024px) {
        .submenu.sub-level-1 {
            padding-left: 2em;
        }

        .menu-item {
            &.comp-level-1 {
                width: 240px;
            }
        }
    }
</style>